#hero {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 700px;
}
#hero h1 {
    margin-bottom: 20px;
}
#projects {
    padding-bottom: 90px;
}
#projects .project {
    height: 480px;
    display: flex;
    justify-content: space-between;
    padding: 0 60px;
    margin-bottom: 40px;
}
#projects .project.left {
    flex-direction: row-reverse;
}
#projects .project.left .image {
    margin-left: 60px;
    margin-right: 0;
}
#projects .project .image {
    width: auto;
    align-self: center;
    margin-right: 60px;
    height: auto;
    max-width: 40%;
    max-height: 80%;
}
#projects .project .project-text{
    width: 50%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    color: white;
    max-width: 505px;
}
#projects .project .project-text .project-roles {
    display: flex;
    margin: 20px 0;
    flex-wrap: wrap;
}
#projects .project .project-text .project-roles p {
    margin-right: 20px;
    
}

#journal .journal-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}
#journal .journal-header .button {
    margin-bottom: 3px;
}