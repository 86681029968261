#journal .hero {
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
}
#journal .hero .herodesc {
    width: 505px;
    margin-top: 40px;
}

#journal .entry-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1.25%;
    row-gap: 20px;
}
#journal .entry-list .entry {
    width: 32.5%;
}
#journal .entry-list .entry .img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#journal .entry-list .entry .entryTitle {
    margin: 10px 0 5px;
}
#journal .entry-list .entry .entryDate {
    color: #999;
    font-size: 14px;
}
