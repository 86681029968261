#footer .divider {
    width: 100%;
    height: 1px;
    background-color: #D7D7d7;
    margin: 60px 0 40px;
}
#footer .social-links {
    margin-bottom: 40px;
}
#footer .social-links h2 {
    margin-bottom: 10px;
}
#footer .social-links .link-list a{
    margin-right: 20px;
}
#footer .info {
    margin-bottom: 60px;
}
#footer .info .copyright {
    font-family: 'Lora', serif;
    font-size: 20px;
    margin-bottom: 5px;
}
#footer .info .credits {
    color: #8f8f8f;
}