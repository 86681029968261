
.navbar {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 9999;
}
.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}
.navbar ul {
    list-style: none;
    display: flex;
}
.navbar ul li {
    margin-right: 20px;
}