#Project {
    padding-top: 60px;
}

#Project .hero {
    height: calc(100vh - 60px);
    width: 100%;
    background-color: cornflowerblue;
    display: flex;
    justify-content: center;
}
#Project .hero img {
    align-self: center;
    width: auto;
    max-width: 40vw;
    height: auto;
}

#Project .intro {
    background-color: #fafafa;
    width: 100%;
}
#Project .intro .container {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
}
#Project .intro .projTitle {
    display: flex;
    flex-direction: column;
    width: 60%;
}
#Project .intro .projTitle p {
    font-size: 20px;
    margin-top: 40px;
}
#Project .intro .projDesc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 38%;
    max-width: 505px;
}
#Project .intro .projDesc h5 {
    margin-bottom: 10px;
}
#Project .intro .projDesc .projRolesHeader {
    margin-top: 20px;
}
#Project .intro .projDesc .projRoles{
    display: flex;
    flex-wrap: wrap;
}
#Project .intro .projDesc .projRoles p {
    margin-right: 20px;
}

#Project .mainContent {
    margin-top: 60px;
}
#Project .mainContent h2 {
    margin-bottom: 40px;
}
#Project .mainContent p {
    margin-bottom: 20px;
}
#Project .mainContent h6 {
    margin-bottom: 20px;
}
#Project .mainContent img {
    width: 100%;
}

#Project .mainContent .projWidth {
    margin: 0 auto;
    max-width: 820px;
}
#Project .mainContent blockquote {
    background-color: #f5f5f5;
    display: flex;
    padding-left: 10px;
    margin: 40px 0;
}
#Project .mainContent blockquote p{
    font-size: 20px;
    font-family: 'Lora', serif;
    margin: 5px 0;
}
#Project .mainContent section {
    margin: 60px auto!important;
}

#Project .mainContent .fourList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px auto;
    max-width: 820px;
}
#Project .mainContent .fourList h6 {
    margin-bottom: 10px;
}
#Project .mainContent .fourList li {
    width: 45%;
    margin-bottom: 20px;
}
#Project .mainContent .fourList li .listNum {
    position: absolute;
    margin-left: -30px;
}

#Project .mainContent .fullColm {
    margin: 40px 0;
}
#Project .mainContent h4 {
    margin-bottom: 20px;
}


#Project .mainContent .fourRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 60px auto;
}
#Project .mainContent .fourRow .rowItem {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#Project .mainContent .fourRow .rowItem img {
    width: 90%;
    margin-bottom: 20px;
}
#Project .mainContent .fourRow .rowItem h6 {
    text-align: center;
    font-size: 14px;
}

#Project .mainContent .twocolum {
    margin: 150px auto;
    display: flex;
    justify-content: space-between;
}
#Project .mainContent .twocolum .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}
 .twocolum h6 {
    margin-bottom: 20px;
}
#Project .mainContent .twocolum.reverse {
    flex-direction: row-reverse;
}


#Project .mainContent .twocolum .slick-slider {
    width: 80%;
    border:1px solid #f5f5f5;
}

#Project .mainContent .iphoneMock {
    width: 300px;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 00px;
  }
  #Project .mainContent .iphoneMock .iphone {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  #Project .mainContent .iphoneMock video.phoneVid {
    width: 83%;
    margin-left: 1%;
    margin-top: -2%;
    z-index: 2;
  }
  #Project .mainContent .iphoneMock .carousel .carousel-container .slides img {
    width: 93%;
  }
  #Project .mainContent .iphoneMock .carousel .carousel-container {
    margin-left: 20px;
  }


#Project .mainContent .pitchVideo {
    display: flex;
    justify-content: center;
    padding: 100px 0;
    margin-top: 100px;
}
#Project .mainContent .pitchVideo video {
    width: 820px;
    margin: 0 auto;
}

#Project .mainContent .mocks {
    display: flex;
    justify-content: center;
    padding: 100px 0;
}

#Project .mainContent .fullcolmimage {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    margin-bottom: 20px;
    border-radius: 10px;
    gap: 40px;
    align-items: center;
}
#Project .mainContent .fullcolmimage img {
    /* width: auto;
    max-width: 10%;
    height: auto; */
    width: fit-content;
}

#Project .mainContent .fullColmList ul {
    display: flex;
    flex-direction: column;
}
#Project .mainContent .fullColmList li {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
}
#Project .mainContent .fullColmList li h6 {
    margin: 0;
}

#Project .mainContent .widecolm {
    display: flex;
    justify-content: space-between;
    margin: 40px auto;
}
#Project .mainContent .widecolm * {
    width: 18%;
    border: 1px solid #f5f5f5;
}

#Project .mainContent button {
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    padding-bottom: 10px;
    font-weight: 600;
    transition: .2s;
}
#Project .mainContent button:hover {
    opacity: .6;
}
#connectionsSections .connectionsNav {
    margin-bottom: 20px;
}
#connectionsSections .connectionsNav ul {
    display: flex;
    gap: 20px;
}

#connectionsSections .connectionsNavFooter {
    margin-top: 100px;
}
#connectionsSections .connectionsNavFooter ul {
    display: flex;
    gap: 20px;
    align-items: center;
}
#connectionsSections .connectionsNavFooter ul button {
    font-size: 16px;
    padding-bottom: 0;
}
#connectionsSections .connectionsNavFooter ul .line {
    width: 100%;
    height: 1px;
    background-color:#d7d7d7;
}
#connectionsSections .pageSection {
    transition: .2s;
}
.divider {
    width: 100%;
    height: 1px;
    background-color:#d7d7d7;
}