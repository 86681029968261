#Entry .hero {
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
#Entry .hero .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4)
}
#Entry .hero .text-wrapper .title {
    color: white;
    text-align: center;
}
#Entry .hero .text-wrapper .date {
    color: white;
    font-style: italic;
    font-size: 20px;
    opacity: .6;
    margin-top: 40px;
}

#Entry .content {
    width: 610px;
    margin: 60px auto 100px;
}
#Entry .content .textBlock {
    margin-bottom: 20px;
    line-height: 160%;
}
#Entry .content p {
    margin-bottom: 20px;
}
#Entry .content a {
    color: #2F2BE5;
    font-weight: 600;
}
#Entry .content blockquote {
    background-color: #f5f5f5;
    border-left: 5px solid #2F2BE5;
    display: flex;
    padding-left: 10px;
    margin-bottom: 20px;
}
#Entry .content blockquote p{
    font-size: 20px;
    font-family: 'Lora', serif;
    margin: 5px 0;
}