@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    color: #333;
}
* {
    padding: 0;
    margin: 0;
}
p {
    line-height: 160%;
}
h1 {
    font-family: 'Lora', serif;
    font-size: 60px;
}
h2 {
    font-family: 'Lora', serif;
    font-size: 40px;
    
}
h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    letter-spacing: -.5px;
    line-height: 140%;
    font-weight: 300;
}
h4 {
    font-size: 20px;
    line-height: 140%;
}
h5 {
    text-transform: uppercase;
    letter-spacing: .3px;
    font-size: 16px;
}
h6 {
    font-size: 16px;
    font-weight: 600;
}
.c {
    font-size: 12px;
}
ul {
    list-style: none;
}

.primary-color {
    color: #2F2BE5;
}
.page-margin {
    margin: 0 100px;
    max-width: 1240px;
    min-width: 820px;
}
.last {
    margin: 0!important;
}
.page {
    padding-top: 60px;
}
a {
    color: #333;
    text-decoration: none;
    transition: 0.2s;
}
a:hover {
    opacity: .7;
}

#Entry #journalFooter {
    margin: 0 100px;
    max-width: 1240px;
    min-width: 820px;
}
#journalFooter h2 {
    margin: 30px 0;
}
#journalFooter .entry-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1.25%;
}
#journalFooter .entry-list .entry {
    width: 32.5%;
}
#journalFooter .entry-list .entry .img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#journalFooter .entry-list .entry .entryTitle {
    margin: 10px 0 5px;
}
#journalFooter .entry-list .entry .entryDate {
    color: #999;
    font-size: 14px;
}

/*slick styling*/

.slick-slider {
    width: 100%;
    border:1px solid #f5f5f5;
}
.slick-prev:before {
    color: #333!important;
  }
  .slick-next:before {
    color: #333!important;
  }
  /* .slick-slider img {
    border:1px solid #f5f5f5;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  } */

@media only screen and (min-width: 1440px) {
    .page-margin {
        margin: 0 auto;
        max-width: 1240px;
        min-width: 820px;
    }
    #Entry #journalFooter {
        margin: 0 auto;
        max-width: 1240px;
        min-width: 820px;
    }
}