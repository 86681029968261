#About .hero {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
}

#About .hero .image{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
}
#About .hero .image .profile{
    width: 400px;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#About .hero .about-text {
    min-width: 400px;
    max-width: 505px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#About .hero .about-text h1{
    margin-bottom: 20px;
}
#About .hero .about-text .text p {
    margin-bottom: 20px;
}

#About #work-history {
    margin-top: 100px;
}
#About #work-history .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
}
#About #work-history .jobs .job {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 60px;
}
#About #work-history .jobs .job .desc{
    min-width: 400px;
    max-width: 505px;
    width: 40%;
}
#About #work-history .jobs .job .info{
    display: flex;
    align-items: center;
}
#About #work-history .jobs .job .info .divider {
    height: 1px;
    width: 20px;
    background-color: #333;
    margin: 0 20px;
}
#About #interests{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 100px;
}
#About #interests .header {
    width: 50%;
}
#About #interests .header h2 {
    margin-bottom: 20px;
}
#About #interests ul {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    list-style: none;
    margin-top: 10px;
    justify-content: space-between;
}
#About #interests ul li {
    width: 50%;
    margin-bottom: 30px;
}